'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.notifications.factory:NotificationsManager

 # @description

###
angular
  .module 'mundoAdmin.notifications'
  .factory 'NotificationsManager', [
    'MundoDefaultManager'
    'MundoNotifications'
    (
      MundoDefaultManager
      MundoNotifications
    )->
      NotificationsManagerBase = new MundoDefaultManager()

      NotificationsManagerBase.setUrl('notifications')
      NotificationsManagerBase.setActions(['list', 'print', 'search', 'refresh', 'delete'])
      NotificationsManagerBase.setSearchFields(['label', 'comments', 'parameters'])
      NotificationsManagerBase.defaultQuery.sort = '-createdAt'

      NotificationsManagerBase.getListSchema = ->
        [
          key: 'label'
          title: 'Label'
          sort: 'label'
        ,
          key: 'comments'
          title: 'Comments'
          sort: 'comments'
        ,
          key: 'tags'
          title: 'Tags'
          sort: 'tags'
          type: 'array'
        ,
          key: 'isRead'
          title: 'Is Read'
          sort: 'isRead'
          type: 'boolean'
        ,
          key: 'createdAt'
          title: 'datatable.label.created.at'
          sort: 'createdAt'
          type: 'dateTime'
        ]

      NotificationsManagerBase.addExtraAction
        icon: 'archive'
        text: 'datatable.tooltips.markAsRead'
        action: (id, row) ->
          row.isRead = true

          NotificationsManagerBase
            .transport
            .one(NotificationsManagerBase.getUrl(), id)
            .patch
              isRead: true

      NotificationsManagerBase.valuePostProcessor = (path, value, row) ->
        if path in ['label', 'comments']
          value = MundoNotifications.renderTemplateContent value, row.parameters

        value

      # NotificationsManagerBase.getDetailTemplateUrl = ->
      #   'mundo-admin/tasks/views/task-detail.tpl.html'

      NotificationsManagerBase
  ]
